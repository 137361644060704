import request from '@/utils/request'
import { parseStrEmpty } from '@/utils/ruoyi'






// 订阅趋势
export function vipData (query) {
  return request({
    url: '/biz/app-feedbacks/vipData',
    method: 'get',
    params: query
  })
}

// 上方数据
export function getTopData (query) {
  return request({
    url: '/biz/app-feedbacks/getTopData',
    method: 'get',
    params: query
  })
}

// 订阅地域排行
export function gerVipAddressData (query) {
  return request({
    url: '/biz/app-feedbacks/gerVipAddressData',
    method: 'get',
    params: query
  })
}


//充值地域排行
export function gerPayAddressData (query) {
  return request({
    url: '/biz/app-feedbacks/gerPayAddressData',
    method: 'get',
    params: query
  })
}


//充值方式累计金额统计
export function gerPayMoneyData (query) {
  return request({
    url: '/biz/app-feedbacks/gerPayMoneyData',
    method: 'get',
    params: query
  })
}


//充值方式统计
export function payTypeData (query) {
  return request({
    url: '/biz/app-feedbacks/payTypeData',
    method: 'get',
    params: query
  })
}



//充值趋势
export function rechargeData (query) {
  return request({
    url: '/biz/app-feedbacks/rechargeData',
    method: 'get',
    params: query
  })
}